<template>
  <div>
    <div class="shopdetail" style="background: #f6f9fc">
      <Tabs></Tabs>
      <div class="content">
        <div style="background: #fff">
          <div style="padding: 60px 0 80px">
            <step :num="2" />
          </div>
          <div class="detail">
            <div class="t">
              <img src="../../assets/img/success@2x.png" alt="" />
              您的订单已提交！请在<span>{{ cuttime }}</span
              >内完成支付，否则订单自动取消
            </div>
            <p>
              <span>订单日期：</span>
              <span>{{ getLocalTime(orderinfo.createtime) }}</span>
            </p>
            <p>
              <span>订单编号：</span> <span> {{ orderinfo.order_sn }}</span>
            </p>
            <p>
              <span>商品名称：</span>
              <span> {{ orderinfo.order_title }}</span>
            </p>
          </div>
        </div>
        <div class="list">
          <h2>选择支付方式</h2>
          <div class="pay">
            <el-radio
              :class="radio == 2 ? 'act' : ''"
              v-model="radio"
              label="2"
            >
              <img
                src="../../assets/img/93e68553c51e7983df4a3dda61633ad.png"
                alt=""
              />
              支付宝支付</el-radio
            >
            <el-radio
              :class="radio == 1 ? 'act' : ''"
              v-model="radio"
              label="1"
            >
              <img
                src="../../assets/img/b3663314b89505124e16b10b712c4cf.png"
                alt=""
              />
              微信支付</el-radio
            >
          </div>
          <div class="amount">
            <div class="t">
              实付金额：<span>¥{{ orderinfo.total_price }}</span>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <div @click="goshop" class="buton">立即支付</div>
          </div>
        </div>
      </div>
      <el-dialog
        custom-class="isdel"
        class="bmdialog"
        title="支付结果确认"
        :visible.sync="isdelete"
        width="440px"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <p>
          请在新打开的页面中完成支付，<br />
          支付完成后，请根据支付结果点击下方按钮
        </p>
        <div slot="footer" class="dialog-footer">
          <span @click="isshop"> 支付成功 </span>
          <span>
            <a style="color: #fff" href="/help/help.html" target="_blank"
              >支付遇到问题</a
            >
          </span>
        </div>
      </el-dialog>
      <el-dialog
        custom-class="isdel"
        class="bmdialog"
        title="订单超时"
        :visible.sync="iserror"
        width="440px"
        @before-close="$router.go(-2)"
        :show-close="false"
      >
        <p>订单超时，请重新下单！</p>
        <div slot="footer" class="dialog-footer">
          <span @click="$router.go(-2)"> 返回下单 </span>
          <span @click="$router.go(-2)"> 取消 </span>
        </div>
      </el-dialog>
      <el-dialog
        custom-class="wxpay"
        class="wxpay"
        ref="wxpay"
        title="订单超时"
        :visible.sync="iswx"
        width="440px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <div slot="title" class="wx-title">
          <img src="../../assets/img/wxpay@2x.png" alt="" />
          微信支付
        </div>
        <div class="qrcode" ref="qrCodeUrl"></div>
        <h2>请使用微信扫码支付</h2>
        <div class="botom">
          <img src="../../assets/img/serve.png" alt="" />
          客服电话：400-656-1156
        </div>
        <img
          @click="wxpay"
          class="esc"
          src="../../assets/img/cuo@2x.png"
          alt=""
        />
      </el-dialog>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import step from "../../components/step/index.vue";
import QRCode from "qrcodejs2";
import api from "@/api/api";
export default {
  name: "Step1",
  components: {
    step,
  },
  data() {
    return {
      cuttime: "",
      radio: "2",
      deletei: 0,
      iswx: false,
      isdelete: false,
      iserror: false,
      order_sn: "",
      orderinfo: {
        surplus_time: 0,
        order_title: "",
        order_sn: "",
        createtime: "",
        total_price: 0,
      },
    };
  },
  watch: {},
  created() {
    this.order_sn = this.$route.params.order_sn;
    api.order_info_v1({ order_sn: this.$route.params.order_sn }).then((res) => {
      this.orderinfo = res.data.data;
      this.cutdown(res.data.data.surplus_time);
    });
  },
  methods: {
    wxpay() {
      this.isshop();
      this.iswx = false;
      this.$refs.qrCodeUrl.innerHTML = "";
    },
    cutdown(time) {
      this.time = time;
      this.timer = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.timer);
          this.iserror = true;
        } else {
          this.time--;
          let a = this.time / 60;
          let b = this.time % 60;
          this.cuttime = parseInt(a) + "分" + b + "秒";
        }
      }, 1000);
    },
    goshop() {
      if (this.radio == 2) {
        let routeData = this.$router.resolve({
          query: { type: 2, order_sn: this.orderinfo.order_sn },
          path: "/order/alipay",
        });
        window.open(routeData.href, "_blank");
        this.isdelete = true;
      } else {
        this.iswx = true;
        api
          .web_pay({
            order_sn: this.orderinfo.order_sn,
            pay_type: Number(this.radio),
          })
          .then((res) => {
            let qrcode = new QRCode(this.$refs.qrCodeUrl, {
              text: res.data.data.code_url,
              width: 128,
              height: 128,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.M,
            });
            // qrcode.clear(); // clear the code.
            // qrcode.makeCode(res.data.data.code_url); // make another code.
          });
      }
    },
    isshop() {
      api
        .pay_success_data({ order_sn: this.orderinfo.order_sn })
        .then((res) => {
          console.log(res);
          if (parseInt(res.data.code) == 401) {
            this.$message.error({
              message: "请先登录!",
              duration: "2000",
            });
            sessionStorage.setItem("logintype", 1);
            this.$router.push({
              name: "Login",
            });
          } else if (res.data.code == 1) {
            this.$message.success("支付成功!");
            this.$router.push({ path: "/order/order_step3" });
            this.$store.commit("Payinfo", res.data.data);
            this.isdelete = false;
          } else {
            this.$message.error("支付失败!");
            this.isdelete = false;
          }
        })
        .catch((e) => {
          this.$message.error("支付失败!");
          this.isdelete = false;
        });
    },
    getLocalTime(str) {
      var n = parseInt(str) * 1000;
      var D = new Date(n);
      var year = D.getFullYear(); //四位数年份

      var month = D.getMonth() + 1; //月份(0-11),0为一月份
      month = month < 10 ? "0" + month : month;

      var day = D.getDate(); //月的某一天(1-31)
      day = day < 10 ? "0" + day : day;

      var hours = D.getHours(); //小时(0-23)
      hours = hours < 10 ? "0" + hours : hours;

      var minutes = D.getMinutes(); //分钟(0-59)
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // var seconds = D.getSeconds();//秒(0-59)
      // seconds = seconds<10?('0'+seconds):seconds;
      // var week = D.getDay();//周几(0-6),0为周日
      // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

      var now_time =
        year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      return now_time;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3e7eff;
  border-color: #3e7eff;
}
.act {
  border: 2px solid #3e7eff !important;
  background: rgba(62, 126, 255, 0.1);
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #333333;
}
::v-deep .el-radio__label {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #333333;
  img {
    margin: 0 10px;
  }
}
// .actt {
//   border: 2px solid #4f76fb !important;
//   background: rgba(79, 118, 251, 0.5);
// }
::v-deep .el-radio {
  width: 350px;
  height: 120px;
  border: 2px solid #f5f6f6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding-left: 42px;
  .el-radio__inner {
    width: 20px;
    height: 20px;
  }

  .el-radio__inner::after {
    width: 8px;
    height: 8px;
  }
}
::v-deep .el-button--primary {
  background-color: #3e7eff;
  border-color: #3e7eff;
}
.content {
  width: 1200px;
  margin: 24px auto 109px;
}
.list {
  background: #fff;
  padding: 0 30px;
  overflow: hidden;
  h2 {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin: 30px 0 20px;
  }
  .pay {
    display: flex;
  }
}
.detail {
  padding-top: 55px;
  margin: 0 40px 20px;
  background: #fff;
  padding-bottom: 30px;
  border-top: 1px solid #eee;
  .t {
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    img {
      width: 51px;
      margin-right: 12px;
    }
    span {
      color: #3e7eff;
    }
  }
  p {
    display: flex;
    padding-left: 63px;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-top: 14px;
  }
}
.amount {
  margin-top: 100px;
  .t {
    font-size: 16px;
    padding-right: 15px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    color: #7d7f8b;
    align-items: baseline;
    span {
      font-size: 24px;
      font-weight: 600;
      color: #fb3623;
      margin-left: 30px;
    }
  }
  .agreement {
    font-size: 14px;
    color: #999999;
    display: flex;
    margin-top: 11px;
    justify-content: flex-end;
    font-weight: 500;
    span {
      color: #3e7eff;
      cursor: pointer;
    }
  }
}
.buton {
  margin: 22px 0 50px;
  width: 200px;
  cursor: pointer;
  height: 48px;
  background: linear-gradient(90deg, #ff887d 0%, #fe240d 100%);
  border-radius: 24px;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
}
::v-deep .isdel {
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    color: #333333;
    font-size: 18px;
    padding: 21px 0;
    background: #f8f8f8;
    text-align: center;
  }
  .el-dialog__body {
    padding: 40px 15px 30px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #333333;
    span {
      color: #fb3623;
    }
  }
  .el-dialog__footer {
    padding: 0 0 30px;
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      span:first-child {
        margin-right: 16px;
        background: linear-gradient(90deg, #ff887d 0%, #fe240d 100%);
      }
      span:last-child {
        margin-right: 16px;
        background: linear-gradient(90deg, #cfd1d3 0%, #9fa4ab 100%);
      }
      span {
        cursor: pointer;
        width: 120px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }
}

::v-deep .wxpay {
  .esc {
    width: 54px;
    position: absolute;
    bottom: -106px;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog {
    border-radius: 5px;
    // overflow: hidden;
  }
  .el-dialog__header {
    padding: 0;
    background: #f8f8f8;
  }
  .qrcode {
    width: 240px;
    height: 240px;
    margin: 31px auto 13px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 500;
    color: #303030;
    text-align: center;
  }
  .botom {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 35px;
    font-weight: 500;
    padding: 14px 0 12px;
    background: #3e7eff;
    color: #ffffff;
    img {
      width: 24px;
    }
  }
}
.wx-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
  font-size: 28px;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
  img {
    width: 54px;
    margin-right: 17px;
  }
  
}
</style>