<template>
  <div>
    <div class="shopdetail" style="background: #f6f9fc">
      <Tabs></Tabs>
      <div class="content">
        <div style="background: #fff">
          <div style="padding: 60px 0 80px">
            <step :num="3" />
          </div>
          <div class="detail">
            <div class="t">
              <img src="../../assets/img/success@2x.png" alt="" />
              <p>恭喜您！支付成功</p>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="table">
            <el-table :data="shop_goods" style="width: 100%">
              <el-table-column prop="order_title" label="课程名称">
                <template slot-scope="scope">
                  <div class="name">
                    {{ scope.row.order_title.trim() }}
                    <!-- <span
                      @click="
                        dialogVisible = true;
                        checked = true;
                      "
                      >购课须知</span
                    > -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="320" prop="order_sn" label="订单编号">
                <template slot-scope="scope">
                  <div style="color:#666" class="name">
                    {{ scope.row.order_sn }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="280" prop="pay_time" label="支付时间">
                <template slot-scope="scope">
                  <div style="color:#666" class="name">
                    {{ getLocalTime(scope.row.pay_time) }}
                  </div>
                </template></el-table-column
              >
              <el-table-column width="200" prop="total_price" label="价格">
                <template slot-scope="scope">
                  <div style="color: #fb3623" class="name">
                    ￥ {{ scope.row.total_price }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="buton" @click="$router.push({ path: '/personal/8' })">
            立即学习
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import step from "../../components/step/index.vue";
import QRCode from "qrcodejs2";
import api from "@/api/api";
export default {
  name: "Step1",
  components: {
    step,
  },
  data() {
    return {
      cuttime: "",
      radio: "2",
      deletei: 0,
      isdelete: false,
      iserror: false,
      shop_goods: [],
    };
  },
  watch: {},
  created() {
    // console.log(this.$store.state.payinfo)
    this.shop_goods.push(this.$store.state.payinfo);
  },
  methods: {
    isshop() {
      api
        .pay_success_data({ order_sn: this.orderinfo.order_sn })
        .then((res) => {
          console.log(res);
          this.$message.success("支付成功!");
          this.$router.push({ path: "" });
          this.isdelete = false;
        })
        .catch((e) => {
          this.$message.error("支付失败!");
          this.isdelete = false;
        });
    },
    getLocalTime(str) {
      var n = parseInt(str) * 1000;
      var D = new Date(n);
      var year = D.getFullYear(); //四位数年份

      var month = D.getMonth() + 1; //月份(0-11),0为一月份
      month = month < 10 ? "0" + month : month;

      var day = D.getDate(); //月的某一天(1-31)
      day = day < 10 ? "0" + day : day;

      var hours = D.getHours(); //小时(0-23)
      hours = hours < 10 ? "0" + hours : hours;

      var minutes = D.getMinutes(); //分钟(0-59)
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // var seconds = D.getSeconds();//秒(0-59)
      // seconds = seconds<10?('0'+seconds):seconds;
      // var week = D.getDay();//周几(0-6),0为周日
      // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

      var now_time =
        year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      return now_time;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 1200px;
  margin: 24px auto 109px;
}
.list {
  background: #fff;
  padding: 0 30px;
  overflow: hidden;
  .table {
    border: 1px solid #eee;
  }
}
::v-deep .el-table th.el-table__cell {
  background: #f5f6f6;
}
::v-deep .el-table thead {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
::v-deep .el-table .el-table__cell {
  padding: 19px 0;
}
::v-deep .el-table th.el-table__cell > .cell,
::v-deep .el-table .cell {
  font-weight: 500;
  padding: 0 40px;
}
::v-deep .el-table {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
.detail {
  padding-top: 55px;
  margin: 0 40px 0px;
  background: #fff;
  padding-bottom: 54px;
  border-top: 1px solid #eee;
  .t {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 82px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
    }
  }
}
.buton {
  width: 220px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  margin: 56px auto 40px;
  font-size: 22px;
  cursor: pointer;
  border-radius: 24px;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(90deg, #abc7ff 0%, #3e7eff 100%);
}
</style>