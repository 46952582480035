<template>
  <div>
    <div class="shopdetail" style="background: #f6f9fc">
      <Tabs></Tabs>
      <div class="content">
        <div style="padding: 60px 0 80px">
          <step :num="1" />
        </div>
        <div class="list">
          <p>商品信息确认</p>
          <div class="table">
            <el-table :data="shop_goods" style="width: 100%">
              <el-table-column width="600" prop="name" label="课程名称">
                <template slot-scope="scope">
                  <div class="name">
                    {{ scope.row.name.trim()
                    }}<span @click="isshow = true">购课须知</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="price" label="价格">
                <template slot-scope="scope">
                  <div class="price">￥ {{ scope.row.price }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="
                      isdelete = true;
                      deletei = scope.$index;
                    "
                    type="text"
                    size="small"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="detail">
            <p class="p">
              <span class="l">已选课程：</span>
              <span class="r">{{ shop_goods.length }}</span>
            </p>
            <p class="p">
              <span class="l">总金额：</span>
              <span class="r">¥{{ price }}</span>
            </p>
            <p class="p">
              <span class="l">优惠金额：</span> <span class="r">0</span>
            </p>
          </div>
          <div class="amount">
            <div class="t">
              实付金额：<span>¥{{ price }}</span>
            </div>
            <div class="agreement">
              <el-checkbox
                style="margin-right: 5px"
                v-model="checked"
              ></el-checkbox
              >我已阅读并接受
              <span @click="isshow = true">《勤学教育购课须知》</span>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <div class="buton" @click="orders">提交订单</div>
          </div>
        </div>
      </div>

      <el-dialog
        title="勤学教育购课须知"
        :close-on-click-modal="false"
        :show-close="false"
        class="isshow"
        :visible.sync="isshow"
        width="697px"
        height="520px"
      >
        <div class="body">
          <div>
            <p>
              请您务必认真阅读
              <span @click="dialogVisible = true">《勤学教育购课须知》</span
              >以便为您提供更好的服务。
            </p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="nosure" @click="isshow = false">不同意</el-button>
          <el-button
            class="sure"
            type="primary"
            @click="
              checked = true;
              isshow = false;
            "
            >同意</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        class="bmdialog"
        title="勤学教育购课须知"
        :visible.sync="dialogVisible"
        width="1000px"
      >
        <iframe
          :src="agreement_url"
          width="100%"
          height="500px"
          frameborder="0"
        ></iframe>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              dialogVisible = false;
              isshow = false;
              checked = true;
            "
            type="primary"
            >我已阅读并同意</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        custom-class="isdel"
        class="bmdialog"
        title="温馨提示"
        :visible.sync="isdelete"
        width="440px"
        :show-close="false"
      >
        <p>
          您确认要从订单删除<br />
          <span>{{ shop_goods[deletei].name.trim() }}</span
          >吗？
        </p>
        <div slot="footer" class="dialog-footer">
          <span @click="deleteRow(deletei)"> 确定 </span>
          <span @click="isdelete = false"> 取消 </span>
        </div>
      </el-dialog>

      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import step from "../../components/step/index.vue";
import api from "@/api/api";
export default {
  name: "Step1",
  components: {
    step,
  },
  data() {
    return {
      isshow: false,
      deletei: 0,
      isdelete: false,
      dialogVisible: false,
      shop_goods: [
        {
          name: "",
        },
      ],
      price: 0,
      checked: false,
      agreement_url: "",
    };
  },
  watch: {
    shop_goods(val) {
      let price = 0;
      val.forEach((e) => {
        price = price + Number(e.price);
      });
      this.price = price.toFixed(2);
    },
  },
  created() {
    this.goodsID = this.$store.state.shop_detail.goodID;
    console.log(this.$store.state.shop_detail);
    api
      .buy_goods_data({
        course_ids: this.$store.state.shop_detail.ids.toString(),
      })
      .then((res) => {
        if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          sessionStorage.setItem("logintype", 1);
          this.$router.push({
            name: "Login",
          });
        }
        this.shop_goods = res.data.data.list;
        this.agreement_url = res.data.data.agreement_url;
      })
      .catch((err) => {});
  },
  methods: {
    orders() {
      if (this.checked) {
        let a = [];
        this.shop_goods.forEach((e) => {
          a.push(e.id);
        });
        api
          .submit_order(
            this.qs.stringify({
              product_type: this.$store.state.shop_detail.type,
              course_ids: a.toString(),
              create_order_type: 4,
              goods_id: this.$store.state.shop_detail.goodID,
            })
          )
          .then((res) => {
            if (parseInt(res.data.code) == 401) {
              this.$message.error({
                message: "请先登录!",
                duration: "2000",
              });
              sessionStorage.setItem("logintype", 1);
              this.$router.push({
                name: "Login",
              });
            } else {
              this.$router.replace({
                path: `/order/order_step2/${res.data.data.order_sn}`,
              });
            }
            // this.$store.commit("OrderDetail", res.data.data);
          });
      } else {
        this.$message.error("请先阅读并同意勤学教育购课须知！");
        this.isshow = true;
      }
    },
    deleteRow(a) {
      if (this.shop_goods.length == 1) {
        this.$message.error("最少保留一个课程哦~");
        this.isdelete = false;
      } else {
        this.shop_goods.splice(a, 1);
        this.deletei = 0;
        this.isdelete = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #4f76fb;
  border-color: #4f76fb;
}
::v-deep .el-button--primary {
  background-color: #4f76fb;
  border-color: #4f76fb;
}
.content {
  width: 1200px;
  background: #fff;
  margin: 24px auto 109px;
}
.list {
  padding: 0 30px;
  overflow: hidden;
  p {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .table {
    border: 1px solid #eee;
  }
}
::v-deep .el-table th.el-table__cell {
  background: #f5f6f6;
}
::v-deep .el-table thead {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
::v-deep .el-table .el-table__cell {
  padding: 19px 0;
}
::v-deep .el-table {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
::v-deep .el-button--text {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}
::v-deep .el-table th.el-table__cell:first-child > .cell {
  padding-left: 160px;
}
.name {
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 30px;
  span {
    font-size: 12px;
    color: #e98f3c;
    font-weight: 400;
    padding: 2px 7px;
    line-height: 1;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 8px;
    border: 1px solid #e98f3c;
  }
}
.price {
  color: #fb3623;
}
.detail {
  margin-top: 70px;
  .p {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    justify-content: flex-end;
    color: #7d7f8b;
    text-align: right;
    .l {
      width: 80px;
    }
    .r {
      color: #333333;
      width: 163px;
    }
  }
}
.amount {
  margin-top: 100px;
  .t {
    font-size: 16px;
    padding-right: 15px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    color: #7d7f8b;
    align-items: baseline;
    span {
      font-size: 24px;
      font-weight: 600;
      color: #fb3623;
      margin-left: 30px;
    }
  }
  .agreement {
    font-size: 14px;
    color: #999999;
    display: flex;
    margin-top: 11px;
    justify-content: flex-end;
    font-weight: 500;
    span {
      color: #3e7eff;
      cursor: pointer;
    }
  }
}
.buton {
  margin: 22px 0 50px;
  width: 200px;
  cursor: pointer;
  height: 48px;
  background: linear-gradient(90deg, #ff887d 0%, #fe240d 100%);
  border-radius: 24px;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
}
::v-deep .isdel {
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    color: #333333;
    font-size: 18px;
    padding: 21px 0;
    background: #f8f8f8;
    text-align: center;
  }
  .el-dialog__body {
    padding: 40px 15px 30px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #333333;
    span {
      color: #fb3623;
    }
  }
  .el-dialog__footer {
    padding: 0 0 30px;
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      span:first-child {
        margin-right: 16px;
        background: linear-gradient(90deg, #ff887d 0%, #fe240d 100%);
      }
      span:last-child {
        margin-right: 16px;
        background: linear-gradient(90deg, #cfd1d3 0%, #9fa4ab 100%);
      }
      span {
        cursor: pointer;
        width: 120px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }
}
::v-deep .isshow {
  .el-dialog__footer {
    padding: 45px 0 30px;

    .nosure {
      width: 184px;
      padding: 0;
      height: 44px;
    }

    .sure {
      width: 184px;
      padding: 0;
      height: 44px;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button + .el-button {
      margin-left: 80px;
    }
  }

  .el-dialog {
    .el-dialog__header {
      background: #eee;
      padding: 20px;

      .el-dialog__title {
        font-size: 20px;
        font-family: SourceHanSansCN;
        font-weight: bold;
      }
    }

    overflow: hidden;
    border-radius: 20px;
  }

  .el-dialog__body {
    padding: 0;
  }

  .body {
    p {
      margin-top: 45px;
      padding: 0 76px;
      font-size: 16px;
      line-height: 26px;
      color: #333;
      font-weight: normal;
    }

    span {
      color: #409eff;
      cursor: pointer;
      font-weight: normal;
    }

    .el-checkbox__label {
      color: #666 !important;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #666;
    }
  }
}
</style>